import useModal, { EModalTypes, IModal } from "@/hooks/useModal";
import { FC, useCallback, useEffect, useState } from "react";
import styles from "@/components/styles/modal.module.css"
import noticeModal from "@/components/styles/noticeModal.module.css"
import Button from "../button";
import u, { ELocalKey, EVIPtype } from "@/util";
import Img from "../img";



const NoticeModal: FC<IModal> = () => {
    const [modalData, closeModal] = useModal();
    const [buy, setBuy] = useState<string>("");

    useEffect(() => {
        console.log('notice Modal launch --- ', modalData)
        const intervalQX = setInterval(qxData, 30000);
        const currentTime = new Date().getTime() as any;
        const popupShown = localStorage.getItem('FEEDBACK') as any;

        if(!popupShown){
            localStorage.setItem('FEEDBACK', currentTime);
        }

        function qxData() {
            try{
                let mdEle = document.querySelectorAll('.notice_modal');
                console.log('mdEle ----> ', mdEle);
                if(mdEle && mdEle.length > 0){
                    // for(let i=0; i<mdEle.length; i++){
                        // console.log('mdEle single ----> ', mdEle[i]);
                        // if(mdEle[i]) delMd('notice_modal'+i);
                    // }
                    closeModal();
                    stopQX();
                    if(localStorage.getItem('FEEDBACK')){
                        localStorage.removeItem('FEEDBACK');
                    }
                }
            }catch(err){
            }
        }
        
        function stopQX() {
            console.log('stopQX notice Modal')
            clearInterval(intervalQX);
        }

        setTimeout(() => {
            stopQX();
          }, 30000);

      if(window.location.href.indexOf("login") >= 0 && localStorage.getItem(ELocalKey._BT)){
        setBuy(localStorage.getItem(ELocalKey._BT)+"")
    }
    }, [])

    const handleBuy = useCallback((price?: any) => {
        let id =window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1,window.location.pathname.length);
        if(localStorage.getItem(ELocalKey._BT)){
            id = localStorage.getItem(ELocalKey._BT)?.substring(0, localStorage.getItem(ELocalKey._BT)?.lastIndexOf('-')) ?? '6'
            localStorage.removeItem(ELocalKey._BT)
            return window.open(`/page/topic/${id}`)
        }
        
        if (!u.isLogin()) {
            localStorage.setItem(ELocalKey._BT,id + '-' + price);
            return window.location.href = "/login"
        }
        try {
            let i = price.toString();
            i = u.encrypt(i);
            u.open(`/user/chargetopic?id=`+id+`&i=`+i+`&ty=topic`)
        } catch(err) { 
            console.log(err)
        }
    }, [])

    const delMd = (id: any) =>{
        const element = document?.getElementById(id);

        console.log('delMd----', element);

        if (element !== null) {
            try{
                let mdEle = document.querySelectorAll('.notice_modal');
                console.log('mdEle----', mdEle);

                if(mdEle && mdEle.length > 0){
                    if(id.includes((mdEle.length - 1).toString())){
                        closeModal(); // close all modal
                    }else{
                        element.remove();
                    }
                }
            }catch(err){
                console.log('err----', element);
                element.remove();
                alert(err)
            }
        }
    }

    const handleJump = useCallback((url: any) => {
        if(url){
            if(url.includes('luntan')){
                const val = "luntan."
                let urlary = window.location.origin.split("https://") as any;
                if (urlary.length === 1) {
                    urlary = window.location.origin.split("http://") as any;
                }
                if (urlary.length === 2) {
                    urlary[0] = val;
                }
                urlary = urlary.join("").replace("www.", "");
                return u.open(`https://${urlary}/bbs/main/view-section/24/25`);
            }else{
                return u.open(url)
            }              
        }else{
            return;
        }
    }, [])

    return (
        modalData?.show && modalData?.id ===  EModalTypes.NOTICE && modalData?.data.data.data &&
        modalData?.data.data.data.length > 0 &&
        <>
            {modalData?.data.data.data.map((x: any, i: any) => {
                        return  <>
                        <div className={styles.common} id={`notice_modal`+i}>
                        <div className={`${styles.body} notice_modal`} >
                            <div onClick={() => {
                               delMd(`notice_modal`+i)
                            }} className={styles.close}>X</div>
                            <div className={`${styles.content} ${noticeModal.content}`}>  
                                {modalData?.data.data.imgs.length === 0 && <>
                                    <div className={`${styles.mid_title} mt10`} > {x.title}</div>
                                    <div className={`${styles.content_inner_title} `} style={{paddingLeft: '10px', paddingRight: '10px'}} dangerouslySetInnerHTML={{ __html: x?.content }}></div>
                                </>}

                                {/* if have img */}
                                {modalData?.data.data.imgs && modalData?.data.data.imgs.length > 0 && <>
                                    <Img encryptUrl={modalData?.data.data.imgs[i]} cls="" src={modalData?.data.data.imgs[i]} >
                                                                {(_decryptUrl: any) => {
                                                                    return <>
                                                                        <Img cls="lazy" style={{ objectFit:  'contain', width: "100%", height: "100%" }} src={_decryptUrl} />
                                                                    </>
                                                                }}
                                </Img>                                
                                </>}
                                {x?.url_1 && <>
                                <div className="fl align_center justify_center mb10">
                                <Button cls="btn_sty3 point" event={() => {handleJump(x.url_1)}}>{x.url_1.includes('register') ? '点击注册' : '点击跳转'}</Button>
                                </div></>}
                                </div>
                            </div>
                        </div>
                        </>
            })}
        </>
        
      
    )
}
export default NoticeModal;