import CategoryHead from "@/components/categoryHead"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import styles from "./styles/redeem.module.css"
import Footer from "@/components/footer"
import Back from "@/components/back"
import useAxios from "@/hooks/useAxios"
import useUser from "@/hooks/useUser"
import useRoleAuth from "@/hooks/useRoleAuth"
import u from "@/util"
import Input from "@/components/input";
import useValidation from "@/hooks/useValidation";
import { vID } from "@/const/validate.const"
import useUpdateForm from "@/hooks/useUpdateForm"
import Button from "@/components/button";
import useFlipFlop from "@/hooks/useFlipFlop"
import NoticeMsgModal from "@/components/modal/noticeMsgModal";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal"
import { PROD_SITE } from "@/const/prod.const"

let imgDomain = "";
const regex = /(http|https):\/\//;
if (process.env.NODE_ENV === 'production') {
    if(process.env.REACT_APP_ENV == 'test') {
        imgDomain = `https://mj.wei.do/buildtest${PROD_SITE}/`
    } else {
        imgDomain = `https://mj.wei.do/build${PROD_SITE}/`
    }
    // imgDomain = `https://mj.wei.do/build${PROD_SITE}/`
    // imgDomain = `https://mj.wei.do/buildtest${PROD_SITE}/`
} else {
    imgDomain = ""
}


const UserRedeem: FC = () => {
    useRoleAuth()
    const [user] = useUser()

    console.log('user', user)
    const [formData, setFormData] = useState<any>({
        code: ""
    })
    const updateForm = useUpdateForm(setFormData);
    const validateCondition = useMemo(() => {
            return {
                code: [vID.CODE_EMPTY, vID.MIN_TEXT_6_12],
            }
    }, []) as any

   
    const getQuery = useCallback((str: string) => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        return params.get(str);
    }, [])

    const [isValidSubmit, msgStatus] = useValidation(formData, validateCondition, "code");
    const [flipText, flipHander] = useFlipFlop("确认兑换", "验证中...")
    const { req } = useAxios("user/newRedeemCheck", 'post', false, true);
    const { req: reqRedeem } = useAxios("user/newRedeem", 'post', false, true);
    const [page, setPage] = useState<any>(1)
    const [list, setList] = useState<any[]>([])
    const [qty, setQty] = useState<any>(0)
    const [topics, setTopics] = useState<any[]>([])

    useEffect(() => {
        const intervalQX = setInterval(qxData, 1000);

        function qxData() {
            console.log(user?.token, 'codeQX ',getQuery("code") )
            if(getQuery("code") && user?.token){
                submit(true);
                formData.code = getQuery("code");
                stopQX();
            }
        }
        
        function stopQX() {
            console.log('stopQX code')
            clearInterval(intervalQX);
           }
    }, [user])

    const selectTopic = (id: any) =>{
        console.log('selectTopic',topics)
        console.log('selectTopic list - ',list)
        console.log('selectTopic id - ',id)
        let newTopics:any = [];
        list.map((item)=>{
            if(item.id === id){
                newTopics.push(item.id);
            }
        })
        setTopics(newTopics);
        console.log('selectTopic newTopics',newTopics)
        console.log('selectTopic',topics)
        // let isExist = false;
        // topics.map((item)=>{
        //     if(item === id){
        //         isExist = true;
        //     }else{
        //         newTopics.push(item);
        //     }
        // })

        // if(!isExist){
        //     newTopics.push(id);
        // }

        // if(topics && newTopics.length > qty){
        //     return;
        // }
        // console.log('newTopics',newTopics)
        // setTopics(newTopics);


        // set selection
        list.map((item)=>{
            if(newTopics.includes(item.id)){
                item.selected = true;
            }else{
                item.selected = false;
            }
        })
    }
    const submit = useCallback(async (isAuto: any = false) => {
        if(!isAuto){
            if (!isValidSubmit) return;
        }
        flipHander(1)
        const res = await req({
            code: formData.code,
            token: user?.token
        });
        console.log('rrrr',res)
        if (res?.code === 0) {
            if(res?.data.mode === 'topup'){
                console.log('reqRedeem',formData)
                const res2 = await reqRedeem({
                    code: formData.code,
                    token: user?.token
                });
                if(res2?.code === 0){
                    console.log('rrrr2',res2)
                    modalSubject$.next({
                        id: EModalTypes.NOTICE_MSG,
                        data: [{title: '兑换成功' , content:u.appendAlertMsg("请到个人中心【已购买主题】查看记录", imgDomain, false)}],
                        event: () => flipHander(0)
                    })
                }else{
                    modalSubject$.next({
                        id: EModalTypes.NOTICE_MSG,
                        data: [{title: '兑换失败' , content:u.appendAlertMsg(res2?.msg, imgDomain, true)}],
                        event: () => flipHander(0)
                    })
                    flipHander(0)
                }
            }else{
                // topic let user choose
                // quantity able tochoose
                // topics can choose
                setPage(2);

                let newList:any = [];
                res?.data?.topics.map((item: any)=>{
                    item.selected = false;
                    newList.push(item);
                })
                setList(newList);
                setQty(res?.data?.quantity);
            }
        }else{
            modalSubject$.next({
                id: EModalTypes.NOTICE_MSG,
                data: [{title: '兑换失败' , content:u.appendAlertMsg(res?.msg, imgDomain, true)}],
                event: () => flipHander(0)
            })
            flipHander(0)
        }

    }, [isValidSubmit, flipHander, formData])

    const submitTopic = useCallback(async () => {
        console.log(formData, 'submitTopic', isValidSubmit)
        if (!isValidSubmit) return;

        if(topics && topics.length === 0){
            modalSubject$.next({
                id: EModalTypes.NOTICE_MSG,
                data: [{title: '兑换失败' , content:u.appendAlertMsg("请选择主题", imgDomain, true)}],
                event: () => flipHander(0)
            })
            return;
        }
      

            formData.topic_ids = topics;
            const res2 = await reqRedeem({
                code: formData.code,
                topic_ids: formData.topic_ids.toString(),
                token: user?.token
            });
            if(res2?.code === 0){
                    console.log('rrrr2',res2)
                    modalSubject$.next({
                        id: EModalTypes.NOTICE_MSG,
                        data: [{title: '兑换成功' , content:u.appendAlertMsg("请到个人中心【已购买主题】查看记录", imgDomain, false)}],
                        event: () => flipHander(0)
                    })
                    flipHander(0);
                    setPage(1);
                    setTopics([]);
            }else{
                modalSubject$.next({
                    id: EModalTypes.NOTICE_MSG,
                    data: [{title: '兑换失败' , content:u.appendAlertMsg("兑换失效(ERROR:404)", imgDomain, true)}],
                    event: () => flipHander(0)
                })
            }
    }, [isValidSubmit, flipHander, formData])
    return (
        <CategoryHead>
            <NoticeMsgModal/>
            <div className="plr mh700 mt80">
                <div className="mw1100 mb10">
                    <Back>兑换页面</Back>
                    <hr></hr>
                    {/* <div className={styles.tabwrapper}>
                        <div className={styles.tab}>兑换VIP</div>
                        <div className={styles.tabactive}>兑换主题</div>
                    </div> */}
                    {page === 1 && <>
                        <div className={styles.contentwrapper}>
                        <div>*兑换码：</div>
                        <Input errMsg={msgStatus[0]} value={formData.username} onChange={(e) => updateForm({ ...formData, "code": e })} line placeholder="输入兑换码" />
                        <Button event={submit} cls={`mt40 btn_sty1 ${u.themeBtnCls()}`}>{flipText}</Button>
                        <div className={`mt20 ${styles.shuoming}`}>
                        <div>使用说明</div>
                        <div>1、请输入您的兑换码，点击确认后您可以兑换物品；</div>
                        <div>2、每个兑换码仅可使用一次，使用后失效。</div>
                        </div>
                    </div>
                    </>}
                    {page === 2 && <>
                    <div className={``}>
                        <div>请选择需要兑换的主题：</div>
                        {list && list.length > 0 &&<>
                            <div className={`${styles.topicwrapper}`}>
                            {list.map((x, i) => {
                                return<>
                                <div className={`${x.selected ? styles.topicactive : styles.topic}`}
                                onClick={()=>{selectTopic(x.id)}}>{i+1}. {x.title}</div>
                                </>
                            })}
                            </div>
                        </>}
                        
                        <div className={styles.btnwrapper}>
                            <div className={styles.btn_2} 
                            onClick={()=>{ setPage(1); flipHander(0) }}>返回</div>
                            <div className={styles.btn}
                            onClick={submitTopic}>确认</div>
                        </div>
                    </div>
                    </>}
                    
                </div>
            </div>
            <Footer />
        </CategoryHead>
    )
}
export default UserRedeem