import "intersection-observer"
import { PROD_SITE } from '@/const/prod.const';
import u from '@/util';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

let imgDomain = "";
const regex = /(http|https):\/\//;
if (process.env.NODE_ENV === 'production') {
    if(process.env.REACT_APP_ENV == 'test') {
        imgDomain = `https://mj.wei.do/buildtest${PROD_SITE}/`
    } else {
        imgDomain = `https://mj.wei.do/build${PROD_SITE}/`
    }
    // imgDomain = `https://mj.wei.do/build${PROD_SITE}/`
    // imgDomain = `https://mj.wei.do/buildtest${PROD_SITE}/`
} else {
    imgDomain = ""
}

interface IImg {
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    fill?: boolean;
    style?: CSSProperties,
    cls?: string;
    id?: string;
    ref?: any
    // 默认图
    errorImg?: 1 | 2 | 3,
    click?: () => void;
    errorCheck?: boolean;
    encryptUrl?: string;
    reload?: boolean; // 加密reload
    children?: any
}

const Img: FC<IImg> = ({ children, src, alt, width, height, fill, style, cls, id, errorImg, click, errorCheck, encryptUrl , reload}) => {
    const { ref, inView, entry } = useInView({
        rootMargin: '0px',
        threshold: 0.5,
    });
    const [error, setError] = useState<boolean>(false);
    const [imgSrc, setImgSrc] = useState<string>(`${imgDomain}/images/default/loading/750x422.jpg`)

    useEffect(() => {
        if (src) {
            let _src = src;
            const isBase64 = _src.indexOf("data:") >= 0;
            if (!isBase64 && !regex.test(_src)) {
                _src = `${imgDomain}${_src}`
            }
            setImgSrc(_src);
        } else {
            setImgSrc(`${imgDomain}/images/default/loading/750x422.jpg`)
        }
    }, [src])

    useEffect(() => {
        if (src) return;
        if (error) {
            let _v = "/images/default/loading/750x422.jpg"
            if (errorImg === 2) {
                _v = "/images/default/loading/790x530.jpg"
            }
            setImgSrc(`${imgDomain}${_v}`)
        }
    }, [errorImg, error, src])

    const errHandler = useCallback(() => {
        return setError(true)
    }, [])

    const decryptImg = useCallback( async () => {
        if (imgSrc && imgSrc.indexOf("data") >= 0) return;
        const res = await u.fetchData(encryptUrl);
        if (res) {
            if (typeof res === "string" && res.includes("404 Not Found")) {
                return setImgSrc(`${imgDomain}/images/default/loading/750x422.jpg`)
            }
            let __decrypted = ""
            try {
                __decrypted = res.indexOf("data") >= 0 ? res : u.imgDecrypt(res);
            } catch {
                return setImgSrc(`${imgDomain}/images/default/loading/750x422.jpg`)
            }
            return setImgSrc(__decrypted)
        } else {
            return setImgSrc(`${imgDomain}/images/default/loading/750x422.jpg`)
        }
    }, [encryptUrl, imgSrc])

    useEffect(() => {
        if (encryptUrl && inView) {
            decryptImg()
        }
    }, [encryptUrl, inView, decryptImg])
    
    // useEffect(() => {
    //     if (reload && inView) {
    //         if (encryptUrl) {
    //             decryptImg()
    //         }
    //     }
    // }, [reload, inView,])

    useEffect(() => {
        if (encryptUrl &&  `${src}`.indexOf("data") < 0) {
            return setImgSrc(`${imgDomain}/images/default/loading/750x422.jpg`)
        }
    }, [encryptUrl, src])

    const actualImgEl = useMemo(() => {
        return <img ref={ref} onClick={click} id={id} onError={errHandler} style={style} sizes="100vh" className={`point fill ${cls}`} height={height || 0} width={width || 0} alt={alt || ''} src={imgSrc} />
    }, [ref, click, id, errHandler, style, cls, height, width, alt, imgSrc])

    return (
        <>
            {errorCheck ?
                error ? '' : actualImgEl
                : actualImgEl} 
            {children && children(imgSrc)}
        </>
    )
}

export default Img;